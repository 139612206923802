import { render, staticRenderFns } from "./ShowRealLocation.vue?vue&type=template&id=5795da57&scoped=true&"
import script from "./ShowRealLocation.vue?vue&type=script&lang=js&"
export * from "./ShowRealLocation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5795da57",
  null
  
)

export default component.exports