<template>
  <a-modal
    width="1000px"
    v-model="isShow"
    title="实时位置"
    :mask-closable="false"
    :body-style="{ paddingLeft: 0 }"
  >
    <template slot="footer">
      <a-button class="ant-btn-primary" @click="closeModal">
        关闭
      </a-button>
    </template>
    <location :task-id="taskId" />
  </a-modal>
</template>

<script>
export default {
  name: 'ShowRealLocation',
  props: {
    taskId: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Location: () => import('@/views/location/index')
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style scoped>

</style>
